import { AnyAction, Dispatch, Middleware } from 'redux';

export interface Thunk<R, S, DispatchExt = {}> {
  (dispatch: Dispatch & ThunkDispatch<S> & DispatchExt, getState: () => S): R;
}

export interface ThunkDispatch<S, DispatchExt = {}> {
  <R>(thunk: Thunk<R, S, DispatchExt>): R;
}

export const middleware = <S>(): Middleware<
  ThunkDispatch<S>,
  S,
  Dispatch & ThunkDispatch<S>
> => ({ dispatch, getState }) => next => <R>(
  action: AnyAction | Thunk<R, S>,
) => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }

  return next(action);
};
