import classnames from 'classnames';
import React from 'react';

import style from './squish.module.css';

interface Props {
  className?: string;
  responsive?: boolean;
}

export const Squish: React.ComponentType<Props> = ({
  children,
  className,
  responsive,
}) => (
  <div
    className={classnames(className, style.list, {
      [style.responsive]: !!responsive,
    })}
  >
    {React.Children.map(children, (
      child: any, // eslint-disable-line
    ) =>
      React.cloneElement(child, {
        className: classnames(child.props.className, style.item),
      }),
    )}
  </div>
);
