import classnames from 'classnames';
import React from 'react';

import { PlayingCard, Squish } from '~/components';
import { Deck } from '~/types';

import style from './hand.module.css';

interface Props {
  deck: Deck;
  onSelectCard?(index: number): Promise<void>;
  selectedCard?: number;
}

export const Hand: React.ComponentType<Props> = ({
  deck,
  onSelectCard,
  selectedCard,
}) => (
  <Squish responsive>
    {Deck.getCards(deck).map((card, index) => (
      <div
        key={`${deck}:${card.label}`}
        className={classnames(style.card, {
          [style.selected]: index === selectedCard,
          [style.clickable]: !!onSelectCard,
        })}
      >
        <PlayingCard
          card={card}
          onClick={onSelectCard ? () => onSelectCard(index) : null}
        />
      </div>
    ))}
  </Squish>
);
