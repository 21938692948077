import { Deck, Game, Player, Role, Room, Rounding, User } from '~/types';

export namespace Message {
  export enum Type {
    Ping,
    Pong,
    RemovedFromGame,
    SaveGame,
    SetGameCanChangeCard,
    SetGameDeck,
    SetGameMode,
    SetGameName,
    SetGameRounding,
    SetPlayerCard,
    SetPlayerIsPlaying,
    SetPlayerName,
    SetTargetPlayerRole,
    UpdateActivePlayerId,
    UpdateGame,
    UpdatePlayers,
    UpdateRooms,
    UpdateUsers,
  }

  export type Data = unknown;
}

interface Ping {
  type: Message.Type.Ping;
  data: boolean;
}

interface Pong {
  type: Message.Type.Pong;
  data: boolean;
}

interface RemovedFromGame {
  type: Message.Type.RemovedFromGame;
}

interface SaveGame {
  type: Message.Type.SaveGame;
}

interface SetGameCanChangeCard {
  type: Message.Type.SetGameCanChangeCard;
  data: boolean;
}

interface SetGameDeck {
  type: Message.Type.SetGameDeck;
  data: Deck;
}

interface SetGameMode {
  type: Message.Type.SetGameMode;
  data: Game.Mode;
}
interface SetGameName {
  type: Message.Type.SetGameName;
  data: string;
}

interface SetGameRounding {
  type: Message.Type.SetGameRounding;
  data: Rounding;
}

interface SetPlayerCard {
  type: Message.Type.SetPlayerCard;
  data: number;
}

interface SetPlayerIsPlaying {
  type: Message.Type.SetPlayerIsPlaying;
  data: boolean;
}

interface SetPlayerName {
  type: Message.Type.SetPlayerName;
  data: string;
}

interface SetTargetPlayerRole {
  type: Message.Type.SetTargetPlayerRole;
  data: [Player.Id, Role];
}

interface UpdateActivePlayerId {
  type: Message.Type.UpdateActivePlayerId;
  data: Player.Id;
}

interface UpdateGame {
  type: Message.Type.UpdateGame;
  data: Partial<Game>;
}

interface UpdatePlayers {
  type: Message.Type.UpdatePlayers;
  data: Record<Player.Id, Partial<Player>>;
}

interface UpdateRooms {
  type: Message.Type.UpdateRooms;
  data: Record<Room.Id, Partial<Room>>;
}

interface UpdateUsers {
  type: Message.Type.UpdateUsers;
  data: Record<User.Id, Partial<User>>;
}

export type Message =
  | Ping
  | Pong
  | RemovedFromGame
  | SaveGame
  | SetGameCanChangeCard
  | SetGameDeck
  | SetGameMode
  | SetGameName
  | SetGameRounding
  | SetPlayerCard
  | SetPlayerIsPlaying
  | SetPlayerName
  | SetTargetPlayerRole
  | UpdateActivePlayerId
  | UpdateGame
  | UpdatePlayers
  | UpdateRooms
  | UpdateUsers;
