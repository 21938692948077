import io from 'socket.io-client';

export type SocketError = object;

export type SocketEventHandler = (...args: Array<string | object>) => void;

export class SocketClient {
  private socket: SocketIOClient.Socket;

  public connect(params: object = {}): Promise<void> {
    this.socket = io(params);

    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => resolve());
      this.socket.on('connect_error', (error: SocketError) => reject(error));
    });
  }

  public disconnect(): Promise<void> {
    return new Promise(resolve => {
      if (!this.socket) {
        return resolve();
      }
      this.socket.on('disconnect', () => {
        this.socket = null;
        resolve();
      });
      this.socket.disconnect();
    });
  }

  public emit(event: string, data?: object): Promise<void> {
    return new Promise(resolve => {
      if (!this.socket) {
        return resolve();
      }
      return this.socket.emit(event, data, () => resolve());
    });
  }

  public on(event: string, fn: SocketEventHandler): Promise<void> {
    this.socket.on(event, fn);
    return Promise.resolve();
  }
}
