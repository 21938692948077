import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import {
  Bottom,
  Button,
  Container,
  Content,
  Hand,
  HeaderTitle,
  HostToolbar,
  Join,
  Logo,
  Menu,
  Modal,
  Players,
  PlayingCard,
  SmartHeader,
  Top,
} from '~/components';
import { NotFound } from '~/pages/not-found';
import {
  FetchDataComponent,
  MapDispatchToProps,
  MapStateToProps,
} from '~/redux';
import {
  closeConnection,
  getGame,
  openConnection,
  setPlayerCard,
} from '~/redux/game';
import { Deck, Game, GameError, Player, Role, Room, Rounding } from '~/types';

interface MatchParams {
  id: string;
}

interface StateProps {
  activePlayer: Player;
  players: Player.Collection;
  game: Game;
}

interface DispatchProps {
  getGame(id: Game.Id): Promise<void>;
  openConnection(id: Room.Id): Promise<void>;
  closeConnection(): Promise<void>;
  selectCard(selectedCard: number): Promise<void>;
}

type Props = StateProps & DispatchProps;

const BaseGamePage: FetchDataComponent<Props, MatchParams> = ({
  activePlayer,
  closeConnection,
  game,
  getGame,
  match,
  openConnection,
  players,
  selectCard,
}) => {
  React.useEffect(() => {
    const promise = new Promise(ok => {
      if (game) {
        return ok();
      } else {
        return getGame(match.params.id).then(ok);
      }
    });

    promise.then(() => {
      openConnection(match.params.id);
    });

    return closeConnection;
  }, []);

  if (game) {
    if (game.error === GameError.NOT_FOUND) {
      return <NotFound />;
    }

    if (game.error) {
      let content;

      switch (game.error) {
        case GameError.REMOVED:
          content = 'removed from the game';
          break;
      }

      return (
        <Content>
          <SmartHeader>
            <Logo />
          </SmartHeader>
          <Top />
          <Bottom>
            <Modal visible={true}>
              <Container>{content}</Container>
              <Button to="/" fullWidth>
                ok
              </Button>
            </Modal>
          </Bottom>
        </Content>
      );
    }
  }

  return (
    <Content>
      <Helmet>
        {game && (
          <title>planningpoker.io {game.name && `- ${game.name}`}</title>
        )}
      </Helmet>
      <SmartHeader>
        <Logo />
        {game && game.name && <HeaderTitle>{game.name}</HeaderTitle>}
        {game && activePlayer && <Menu />}
      </SmartHeader>
      <Top>
        {game && (
          <>
            <Container>
              <PlayingCard
                fade
                hidden={game.mode === Game.Mode.Hidden}
                card={
                  game.mode === Game.Mode.Visible
                    ? Rounding.calculate(
                        game.rounding,
                        Deck.getCards(game.deck),
                        Object.values(players)
                          .filter(player => player.hasSelectedCard)
                          .map(player => player.selectedCard),
                      )
                    : null
                }
              />
            </Container>
            <Container>
              <Players
                hidden={game.mode === Game.Mode.Hidden}
                players={players}
                deck={game.deck}
              />
            </Container>
          </>
        )}
      </Top>
      <Bottom>
        {game && activePlayer && (
          <>
            {activePlayer.isPlaying && (
              <Container>
                <Hand
                  deck={game.deck}
                  onSelectCard={
                    game.canChangeCard || game.mode === Game.Mode.Hidden
                      ? selectCard
                      : null
                  }
                  selectedCard={activePlayer.selectedCard}
                />
              </Container>
            )}

            {Player.hasRole(activePlayer, Role.Host) && (
              <Container>
                <HostToolbar />
              </Container>
            )}
          </>
        )}
      </Bottom>
      {game && activePlayer && (
        <Modal visible={!activePlayer.isUser && !activePlayer.isGuest}>
          <Join />
        </Modal>
      )}
    </Content>
  );
};

BaseGamePage.fetchData = async (dispatch, _, match) => {
  await dispatch(getGame(match.params.id));
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  activePlayer: state.players[state.activePlayerId],
  game: state.game,
  players:
    state.game && state.game.playerIds
      ? Player.Collection.fromArray(
          state.game.playerIds
            .map(id => state.players[id])
            .filter(player => !!player),
        )
      : {},
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  closeConnection: () => dispatch(closeConnection()),
  getGame: id => dispatch(getGame(id)),
  openConnection: id => dispatch(openConnection(id)),
  selectCard: selectedCard => dispatch(setPlayerCard(selectedCard)),
});

export const GamePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseGamePage);
