import { v4 as uuid } from 'uuid';

import { Role, User } from '~/types';

import * as generic from './generic';

export interface Player {
  hasSelectedCard: boolean;
  id: Player.Id;
  isPlaying: boolean;
  isGuest: boolean;
  isUser: boolean;
  isPresent: boolean;
  name: string;
  role: Role;
  selectedCard?: number;
  avatar?: string;
}

export namespace Player {
  export type Id = string;
  export type Collection = Record<Id, Player>;

  export namespace Collection {
    export const update = generic.update;
    export const without = generic.without;
    export const entries = generic.entries;
    export const fromArray = generic.arrayToCollection;
  }

  export const create = (defaults?: Partial<Player>): Player => ({
    hasSelectedCard: false,
    id: uuid(),
    isGuest: false,
    isPlaying: false,
    isPresent: false,
    isUser: false,
    name: 'anonymous',
    role: Role.Player,
    ...defaults,
  });

  export const createFromUser = (user: User): Player =>
    Player.create({
      avatar: user.avatar,
      id: user.id,
      isUser: true,
      name: user.name,
    });

  export const hasRole = (player: Player, role: Role): boolean =>
    !!player && Role.has(player.role, role);
}
