import { Middleware } from 'redux';

import { SocketClient, SocketEventHandler } from '~/socket-client';

export const SOCKET = 'SOCKET';

export interface SocketAction {
  type: typeof SOCKET;
  promise: (socket: SocketClient) => Promise<void>;
}

export const connect = (params: object = {}): SocketAction => ({
  promise: (socket: SocketClient) => socket.connect(params),
  type: SOCKET,
});

export const disconnect = (): SocketAction => ({
  promise: (socket: SocketClient) => socket.disconnect(),
  type: SOCKET,
});

export const send = (event: string, data?: object): SocketAction => ({
  promise: (socket: SocketClient) => socket.emit(event, data),
  type: SOCKET,
});

export const on = (event: string, fn: SocketEventHandler): SocketAction => ({
  promise: (socket: SocketClient) => socket.on(event, fn),
  type: SOCKET,
});

export const middleware = (): Middleware => {
  const client = new SocketClient();

  return () => next => action => {
    if (action.type === SOCKET && action.promise !== undefined) {
      return action.promise(client);
    }

    return next(action);
  };
};
