import React from 'react';
import { connect } from 'react-redux';

import { MapDispatchToProps, MapStateToProps } from '~/redux';
import { setGameMode } from '~/redux/game';
import { Game } from '~/types';

interface StateProps {
  game: Game;
}

interface DispatchProps {
  hide(): Promise<void>;
  show(): Promise<void>;
}

type Props = StateProps & DispatchProps;

export const BaseHostToolbar: React.ComponentType<Props> = ({
  game,
  hide,
  show,
}) => {
  const toggle = (): void => {
    if (game.mode === Game.Mode.Hidden) {
      show();
    } else {
      hide();
    }
  };

  const label = game.mode === Game.Mode.Hidden ? 'show cards' : 'hide cards';

  return (
    <div>
      <button onClick={toggle}>{label}</button>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  game: state.game,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  hide: () => dispatch(setGameMode(Game.Mode.Hidden)),
  show: () => dispatch(setGameMode(Game.Mode.Visible)),
});

export const HostToolbar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseHostToolbar);
