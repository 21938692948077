import * as React from 'react';

import { Badge, PlayingCard, PlayingCardSlot, Squish } from '~/components';
import { Deck, Player } from '~/types';

import style from './players.module.css';

interface Props {
  players: Player.Collection;
  deck: Deck;
  hidden: boolean;
}

export const Players: React.ComponentType<Props> = ({
  players,
  deck,
  hidden,
}) => (
  <Squish className={style.list}>
    {Object.values(players)
      .filter(player => player.isPlaying && player.isPresent)
      .map(player => (
        <div key={player.id}>
          <PlayingCardSlot>
            <PlayingCard
              card={Deck.getCards(deck)[player.selectedCard]}
              hidden={hidden}
            />
            <Badge className={style.badge} player={player} />
          </PlayingCardSlot>
        </div>
      ))}
  </Squish>
);
