import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faHandPeace } from '@fortawesome/free-solid-svg-icons/faHandPeace';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons/faUserMinus';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';

library.add(
  faTrash,
  faUser,
  faUsers,
  faShare,
  faCog,
  faShareAlt,
  faHandPeace,
  faPowerOff,
  faCommentDots,
  faSignOutAlt,
  faUserPlus,
  faUserMinus,
  faBullhorn,
);
