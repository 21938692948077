import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button } from '~/components';
import { MapDispatchToProps } from '~/redux';
import { createGame } from '~/redux/game';
import { Game } from '~/types';

interface DispatchProps {
  createGame: () => Promise<Game>;
}

export const BaseCreateGame: React.ComponentType<DispatchProps> = ({
  createGame,
}) => {
  const [redirect, setRedirect] = React.useState(null);

  const start = async (): Promise<void> => {
    const game = await createGame();
    setRedirect(game.id);
  };

  if (redirect) {
    return <Redirect push to={`/games/${redirect}`} />;
  } else {
    return (
      <Button size="large" onClick={start}>
        start playing
      </Button>
    );
  }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  createGame: () => dispatch(createGame()),
});

export const CreateGame = connect(null, mapDispatchToProps)(BaseCreateGame);
