import { Thunk } from '~/redux';
import { GameError, Message } from '~/types';

import * as activePlayerId from './active-player-id';
import * as game from './game';
import * as players from './players';
import * as socket from './socket';
import * as users from './users';

export const send = (
  type: Message.Type,
  data?: Message.Data,
): Thunk<Promise<void>> => async dispatch => {
  dispatch(socket.send('message', { data, type }));
};

export const receive = (message: Message): Thunk<void> => async dispatch => {
  switch (message.type) {
    case Message.Type.RemovedFromGame:
      await dispatch(game.closeConnection());
      await dispatch(game.raiseError(GameError.REMOVED));
      return;
    case Message.Type.Ping:
      await dispatch(send(Message.Type.Pong));
      return;
    case Message.Type.UpdateGame:
      dispatch({
        game: message.data,
        type: game.RECEIVE_GAME,
      });
      return;
    case Message.Type.UpdateUsers:
      dispatch({
        type: users.RECEIVE_USERS,
        users: message.data,
      });
      return;
    case Message.Type.UpdatePlayers:
      dispatch({
        players: message.data,
        type: players.RECEIVE_PLAYERS,
      });
      return;
    case Message.Type.UpdateActivePlayerId:
      dispatch({
        id: message.data,
        type: activePlayerId.SET_ACTIVE_PLAYER_ID,
      });
      return;
  }
};
