import { MapStateToProps as ReduxMapStateToProps } from 'react-redux';
import { match } from 'react-router';
import {
  AnyAction,
  Store as ReduxStore,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';

import { Api } from '~/types';

import {
  Action as ActivePlayerIdAction,
  State as ActivePlayerIdState,
  reducer as activePlayerId,
} from './active-player-id';
import {
  Action as ActiveUserIdAction,
  State as ActiveUserIdState,
  reducer as activeUserId,
} from './active-user-id';
import {
  Action as GameAction,
  State as GameState,
  reducer as game,
} from './game';
import {
  Action as PlayerAction,
  State as PlayerState,
  reducer as players,
} from './players';
import { middleware as promise } from './promise';
import {
  Action as RoomAction,
  State as RoomState,
  reducer as rooms,
} from './rooms';
import { SocketAction, middleware as socket } from './socket';
import { Thunk as AnyThunk, middleware as thunk } from './thunk';
import {
  Action as UserAction,
  State as UserState,
  reducer as users,
} from './users';

export type Action =
  | AnyAction
  | UserAction
  | PlayerAction
  | RoomAction
  | GameAction
  | ActiveUserIdAction
  | ActivePlayerIdAction
  | SocketAction;

export interface State {
  game: GameState;
  players: PlayerState;
  rooms: RoomState;
  users: UserState;
  activeUserId: ActiveUserIdState;
  activePlayerId: ActivePlayerIdState;
  resources: Api;
}

export type Reducer<S, A> = (state: S, action: A) => S;
export type Thunk<T = Promise<void>> = AnyThunk<T, State>;

export interface Dispatch {
  <A extends Action>(action: A): A;
  <T, A extends Thunk<T>>(action: A): T;
}

export type MapStateToProps<
  OwnState = {},
  OwnProps = {}
> = ReduxMapStateToProps<OwnState, OwnProps, State>;

export type MapDispatchToProps<DispatchProps> = (
  dispatch: Dispatch,
) => DispatchProps;

export type Store = ReduxStore<State, Action> & {
  dispatch<T>(thunk: Thunk<T>): Thunk<T>;
};

export type FetchData<T> = (
  dispatch: Dispatch,
  getState: () => State,
  match?: match<T>,
) => Promise<void>;

export type FetchDataComponent<P, T = {}> = React.ComponentType<
  P & { match?: { params: T } }
> & {
  fetchData: FetchData<T>;
};

let composeEnhancers: typeof compose;

if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;
} else {
  composeEnhancers = compose;
}

export const initStore = (state = {}): Store => {
  const reducer = combineReducers({
    activePlayerId,
    activeUserId,
    game,
    players,
    resources: (state: Api = null) => state,
    rooms,
    users,
  });

  const middleware = applyMiddleware(promise(), socket(), thunk());

  return createStore(reducer, state, composeEnhancers(middleware));
};
