import { v4 as uuid } from 'uuid';

import * as generic from './generic';

export interface User {
  email: string;
  id: User.Id;
  name: string;
  avatar?: string;
}

export namespace User {
  export type Id = string;
  export type Collection = Record<Id, User>;

  export namespace Collection {
    export const update = generic.update;
    export const without = generic.without;
    export const fromArray = generic.arrayToCollection;
    export const map = generic.map;
  }

  export const create = (defaults: Partial<User> = {}): User => ({
    email: '',
    id: uuid(),
    name: '',
    ...defaults,
  });
}
