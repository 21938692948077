import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import style from './button.module.css';

interface Props extends React.HTMLAttributes<HTMLElement> {
  onClick?: (event: React.MouseEvent) => void;
  href?: string;
  to?: string;
  className?: string;
  size?: string;
  fullWidth?: boolean;
  type?: string;
}

export const Button: React.ComponentType<Props> = ({
  children,
  className: originalClassName,
  fullWidth,
  href,
  onClick,
  to,
  ...props
}) => {
  const className = classnames(originalClassName, {
    [style.fullWidth]: fullWidth,
  });

  if (to) {
    return (
      <Link to={to}>
        <button {...props} className={className} type="button">
          {children}
        </button>
      </Link>
    );
  } else if (href) {
    return (
      <a href={href}>
        <button {...props} className={className} type="button">
          {children}
        </button>
      </a>
    );
  } else if (onClick) {
    return (
      <button onClick={onClick} {...props} className={className} type="button">
        {children}
      </button>
    );
  } else {
    return (
      <button disabled {...props} className={className} type="button">
        {children}
      </button>
    );
  }
};
