import React from 'react';
import { connect } from 'react-redux';

import { GoogleLogin, LightTheme } from '~/components';
import { MapDispatchToProps, MapStateToProps } from '~/redux';
import { setPlayerIsPlaying, setPlayerName } from '~/redux/game';
import { Game } from '~/types';

import style from './join.module.css';

interface StateProps {
  game: Game;
}

interface DispatchProps {
  startPlaying: () => void;
  setPlayerName: (name: string) => void;
}

type Props = StateProps & DispatchProps;

const getDefaultName = (): string => {
  let name;
  if (typeof localStorage !== 'undefined') {
    name = localStorage.getItem('anonymous.name');
  }
  return name || '';
};

const setDefaultName = (name: string): void => {
  localStorage.setItem('anonymous.name', name);
};

const BaseJoin: React.ComponentType<Props> = ({
  game,
  startPlaying,
  setPlayerName,
}) => {
  const [name, setName] = React.useState(getDefaultName());

  const play: React.EventHandler<React.SyntheticEvent> = event => {
    event.preventDefault();
    setDefaultName(name);
    setPlayerName(name);
    startPlaying();
  };

  return (
    <LightTheme className={style.container}>
      <div className={style.left}>
        <form onSubmit={play}>
          <div>
            <input
              placeholder="your name"
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div>
            <button disabled={name.length === 0}>play as guest</button>
          </div>
        </form>
      </div>
      <div className={style.or}>
        <div className={style.badge}>or</div>
      </div>
      <div className={style.right}>
        <GoogleLogin redirect={`/games/${game.id}`} />
      </div>
    </LightTheme>
  );
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  game: state.game,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  setPlayerName: name => dispatch(setPlayerName(name)),
  startPlaying: () => dispatch(setPlayerIsPlaying(true)),
});

export const Join = connect(mapStateToProps, mapDispatchToProps)(BaseJoin);
