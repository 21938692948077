import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import * as React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';

import { initStore } from '~/redux';
import { routes } from '~/routes';

import * as api from '@client/api';

let ErrorBoundary: React.ComponentType;

if (process.env.BUGSNAG) {
  const bugsnagClient = bugsnag(process.env.BUGSNAG);
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
} else {
  const NoopComponent: React.ComponentType = ({ children }) => <>{children}</>;
  ErrorBoundary = NoopComponent;
}

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

if (!window.__PERSISTENT_STORE__) {
  window.__PERSISTENT_STORE__ = initStore({
    ...preloadedState,
    resources: api.resources,
  });
}

const store = window.__PERSISTENT_STORE__;

hydrate(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
