import React from 'react';

import { Player } from '~/types';

import { Badge } from './badge';
import style from './badge-list.module.css';

interface Props {
  players: Player[];
}

const MAX = 5;

export const BadgeList: React.ComponentType<Props> = ({ players }) => (
  <>
    {players.map((player, index) =>
      index < MAX ? (
        <Badge
          key={player.id}
          className={style.badge}
          player={player}
          overlay={
            index === MAX - 1 && players.length > MAX
              ? `+${players.length - MAX}`
              : null
          }
        />
      ) : null,
    )}
  </>
);
