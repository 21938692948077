import { v4 as uuid } from 'uuid';

import { Deck, Game, Role, Rounding, User } from '~/types';

import * as generic from './generic';

export interface Room {
  canChangeCard: boolean;
  deck: Deck;
  id: Room.Id;
  name: string;
  rounding: Rounding;
  userRole?: Role;
  created: number;
  userIds?: User.Id[];
}

export namespace Room {
  export type Id = string;
  export type Collection = Record<Id, Room>;

  export namespace Collection {
    export const update = generic.update;
    export const without = generic.without;
    export const fromArray = generic.arrayToCollection;
  }

  export const create = (defaults: Partial<Room> = {}): Room => ({
    canChangeCard: false,
    created: Date.now() / 1000,
    deck: Deck.ModifiedFibonacci,
    id: uuid(),
    name: '',
    rounding: Rounding.Up,
    ...defaults,
  });

  export const createFromGame = (game: Game): Room =>
    Room.create({
      canChangeCard: game.canChangeCard,
      deck: game.deck,
      id: game.id,
      name: game.name,
      rounding: game.rounding,
    });
}
