import { Reducer, Thunk } from '~/redux';
import { receiveUsers } from '~/redux/users';
import { Room, User } from '~/types';

export type State = Room.Collection;

export const RECEIVE_ROOMS = 'RECEIVE_ROOMS';
export const REMOVE_ROOMS = 'REMOVE_ROOMS';

interface ReceiveRoomsAction {
  type: typeof RECEIVE_ROOMS;
  rooms: Record<Room.Id, Partial<Room>>;
}

export const receiveRooms = (rooms: Room.Collection): ReceiveRoomsAction => ({
  rooms,
  type: RECEIVE_ROOMS,
});

interface RemoveRoomsAction {
  type: typeof REMOVE_ROOMS;
  ids: Room.Id[];
}

export const removeRooms = (ids: Room.Id[]): RemoveRoomsAction => ({
  ids,
  type: REMOVE_ROOMS,
});

export type Action = ReceiveRoomsAction | RemoveRoomsAction;

export const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ROOMS:
      return Room.Collection.update(state, action.rooms);
    case REMOVE_ROOMS:
      return Room.Collection.without(state, action.ids);
    default:
      return state;
  }
};

export const getRoomsByUserId = (
  id: User.Id,
): Thunk<Promise<Room.Collection>> => async (dispatch, getState) => {
  const { resources } = getState();
  const rooms = await resources.users.getRooms(id);

  const promises = Object.values(rooms).map(async room => {
    const userIds = Object.keys(getState().users);
    const missingUsers = room.userIds.some(userId => !userIds.includes(userId));

    if (missingUsers) {
      const users = await resources.rooms.getUsers(room.id);
      dispatch(receiveUsers(users));
    }
  });

  await Promise.all(promises);

  dispatch(receiveRooms(rooms));

  return rooms;
};

export const getRooms = (): Thunk<Promise<Room.Collection>> => async (
  dispatch,
  getState,
) => {
  const { activeUserId } = getState();

  return await dispatch(getRoomsByUserId(activeUserId));
};

export const createRoom = (
  partial?: Partial<Room>,
): Thunk<Promise<Room>> => async (dispatch, getState) => {
  const { activeUserId, resources } = getState();
  const room = await resources.rooms.createRoom(partial, activeUserId);

  dispatch(
    receiveRooms({
      [room.id]: room,
    }),
  );

  return room;
};

export const leaveRoom = (id: Room.Id): Thunk<Promise<boolean>> => async (
  dispatch,
  getState,
) => {
  const { resources, activeUserId } = getState();
  const success = await resources.rooms.deleteRole(id, activeUserId);

  if (success) {
    dispatch(removeRooms([id]));
  }

  return success;
};

export const deleteRoom = (id: Room.Id): Thunk<Promise<boolean>> => async (
  dispatch,
  getState,
) => {
  const { resources } = getState();

  const success = await resources.rooms.deleteRoom(id);

  if (success) {
    dispatch(removeRooms([id]));
  }

  return success;
};
