// eslint-disable-next-line import/export
export enum Role {
  Player = 0b00,
  Host = 0b01,
  Creator = 0b10,
}

// eslint-disable-next-line import/export
export namespace Role {
  export const has = (a: Role, b: Role): boolean => (a & b) >= b;
}
