import { Reducer, Thunk } from '~/redux';
import {
  Deck,
  Game,
  GameError,
  Message,
  Player,
  Role,
  Room,
  Rounding,
} from '~/types';

import * as message from './message';
import * as players from './players';
import * as socket from './socket';

export type State = Game;

export const RECEIVE_GAME = 'RECEIVE_GAME';

interface ReceiveGameAction {
  type: typeof RECEIVE_GAME;
  game: Partial<Game>;
}

export const receiveGame = (game: Partial<Game>): ReceiveGameAction => ({
  game,
  type: RECEIVE_GAME,
});

export type Action = ReceiveGameAction;

export const reducer: Reducer<State, Action> = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_GAME:
      if (action.game === null) {
        return null;
      } else {
        return { ...state, ...action.game };
      }
    default:
      return state;
  }
};

export const raiseError = (error: GameError): Thunk => async dispatch => {
  dispatch(receiveGame({ error }));
};

export const createGame = (): Thunk<Promise<Game>> => async (_, getState) => {
  const { resources } = getState();
  return await resources.games.createGame();
};

export const getGame = (gameId: Game.Id): Thunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const game = await state.resources.games.getGame(gameId);

  if (game) {
    await dispatch(players.getPlayers(game.id));
    dispatch(receiveGame(game));
  } else {
    dispatch(raiseError(GameError.NOT_FOUND));
  }
};

export const saveGame = (): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SaveGame, name));
};

export const openConnection = (id: Room.Id): Thunk => async dispatch => {
  const query = { id };

  dispatch(socket.connect({ query }));
  dispatch(socket.on('message', m => dispatch(message.receive(m as Message))));
};

export const closeConnection = (): Thunk => async dispatch => {
  dispatch(socket.disconnect());
  dispatch(receiveGame(null));
  dispatch(players.clearPlayers());
};

export const setPlayerIsPlaying = (
  isPlaying: boolean,
): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetPlayerIsPlaying, isPlaying));
};

export const setPlayerCard = (card: number): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetPlayerCard, card));
};

export const setPlayerName = (name: string): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetPlayerName, name));
};

export const setTargetPlayerRole = (
  id: Player.Id,
  role: Role,
): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetTargetPlayerRole, [id, role]));
};

export const setGameName = (name: string): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetGameName, name));
};

export const setGameDeck = (deck: Deck): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetGameDeck, deck));
};

export const setGameRounding = (
  rounding: Rounding,
): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetGameRounding, rounding));
};

export const setGameMode = (mode: Game.Mode): Thunk => async dispatch => {
  await dispatch(message.send(Message.Type.SetGameMode, mode));
};

export const setGameCanChangeCard = (
  canChangeCard: boolean,
): Thunk => async dispatch => {
  await dispatch(
    message.send(Message.Type.SetGameCanChangeCard, canChangeCard),
  );
};
