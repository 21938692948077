import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import {
  Body,
  Bottom,
  Button,
  Content,
  CreateGame,
  HeaderMenu,
  Logo,
  RoomList,
  SmartHeader,
  Top,
  Version,
} from '~/components';
import {
  FetchDataComponent,
  MapDispatchToProps,
  MapStateToProps,
} from '~/redux';
import { getRoomsByUserId } from '~/redux/rooms';
import { Role, Room, User } from '~/types';

interface StateProps {
  activeUserId: User.Id;
  rooms: Room.Collection;
}

interface DispatchProps {
  getRooms: (userId: User.Id) => Promise<Room.Collection>;
}

type Props = StateProps & DispatchProps;

const BaseDashboard: FetchDataComponent<Props> = ({
  activeUserId,
  rooms,
  getRooms,
}) => {
  React.useEffect(() => {
    getRooms(activeUserId);
  }, [activeUserId]);

  const roomList = Object.values(rooms);
  const createdRooms = roomList.filter(({ userRole }) =>
    Role.has(userRole, Role.Creator),
  );
  const sharedRooms = roomList.filter(
    ({ userRole }) => !Role.has(userRole, Role.Creator),
  );

  return (
    <Content>
      <SmartHeader>
        <Logo />
        <HeaderMenu>
          <Button href="/api/auth/logout">log out</Button>
        </HeaderMenu>
      </SmartHeader>
      <Top data-small="true">
        <CreateGame />
      </Top>
      <Bottom>
        <Body>
          {roomList.length > 0 ? (
            <>
              <RoomList title="my games" rooms={createdRooms} />
              <RoomList title="shared games" rooms={sharedRooms} />
            </>
          ) : (
            <h1>no saved games</h1>
          )}
        </Body>
        <Version />
      </Bottom>
      <Helmet>
        <title>planningpoker.io dashboard</title>
      </Helmet>
    </Content>
  );
};

BaseDashboard.fetchData = async (dispatch, getState) => {
  const { activeUserId } = getState();

  await dispatch(getRoomsByUserId(activeUserId));
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  activeUserId: state.activeUserId,
  rooms: state.rooms,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  getRooms: userId => dispatch(getRoomsByUserId(userId)),
});

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseDashboard);
