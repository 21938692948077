import { Api } from '~/types';

import * as games from './games';
import * as rooms from './rooms';
import * as users from './users';

export const resources: Api = {
  games: games.methods,
  rooms: rooms.methods,
  toJSON: null,
  users: users.methods,
};
