import { RouteConfig } from 'react-router-config';

import { GamePage } from '~/pages/game';
import { Landing } from '~/pages/landing';
import { NotFound } from '~/pages/not-found';

import 'normalize.css';
import '~/styles/global.css';
import './icons';

export const routes: RouteConfig[] = [
  {
    component: Landing,
    exact: true,
    path: '/',
  },
  {
    component: GamePage,
    path: '/games/:id',
  },
  {
    component: NotFound,
    path: '*',
  },
];
