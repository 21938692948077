import { Reducer } from '~/redux';
import { User } from '~/types';

export type State = User.Id;

const initialState: State = null;

export const SET_ACTIVE_USER_ID = 'SET_ACTIVE_USER_ID';

interface SetActiveUserIdAction {
  type: typeof SET_ACTIVE_USER_ID;
  id: User.Id;
}

export type Action = SetActiveUserIdAction;

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_ACTIVE_USER_ID:
      return action.id;
    default:
      return state;
  }
};
