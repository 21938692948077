import * as React from 'react';

import { Bottom, Content, Header, Logo, Top } from '~/components';

export const NotFound: React.FunctionComponent = () => (
  <Content>
    <Header>
      <Logo />
    </Header>
    <Top data-large="true">
      <h1>404 not found</h1>
    </Top>
    <Bottom></Bottom>
  </Content>
);
