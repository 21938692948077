import { Card } from '~/types';

export enum Deck {
  Fibonacci,
  ModifiedFibonacci,
  ShirtSizes,
  Thumbs,
}

const DECK_FIBONACCI: Card[] = [
  {
    color: 'blue',
    label: '0',
    value: 0,
  },
  {
    color: 'blue',
    label: '1',
    value: 1,
  },
  {
    color: 'blue',
    label: '2',
    value: 2,
  },
  {
    color: 'blue',
    label: '3',
    value: 3,
  },
  {
    color: 'blue',
    label: '5',
    value: 5,
  },
  {
    color: 'blue',
    label: '8',
    value: 8,
  },
  {
    color: 'green',
    label: '13',
    value: 13,
  },
  {
    color: 'green',
    label: '21',
    value: 21,
  },
  {
    color: 'green',
    label: '34',
    value: 34,
  },
  {
    color: 'green',
    label: '55',
    value: 55,
  },
  {
    color: 'green',
    label: '89',
    value: 89,
  },
  {
    color: 'yellow',
    label: '?',
    value: null,
  },
  {
    color: 'white',
    label: '☕',
    value: null,
  },
];

const DECK_MODIFIED_FIBONACCI: Card[] = [
  {
    color: 'blue',
    label: '0',
    value: 0,
  },
  {
    color: 'blue',
    label: '½',
    value: 0.5,
  },
  {
    color: 'blue',
    label: '1',
    value: 1,
  },
  {
    color: 'blue',
    label: '2',
    value: 2,
  },
  {
    color: 'blue',
    label: '3',
    value: 3,
  },
  {
    color: 'blue',
    label: '5',
    value: 5,
  },
  {
    color: 'blue',
    label: '8',
    value: 8,
  },
  {
    color: 'green',
    label: '13',
    value: 13,
  },
  {
    color: 'green',
    label: '20',
    value: 20,
  },
  {
    color: 'green',
    label: '40',
    value: 40,
  },
  {
    color: 'green',
    label: '100',
    value: 100,
  },
  {
    color: 'yellow',
    label: '?',
    value: null,
  },
  {
    color: 'white',
    label: '☕',
    value: null,
  },
];

const DECK_SHIRT: Card[] = [
  {
    color: 'blue',
    label: 'xs',
    value: 1,
  },
  {
    color: 'blue',
    label: 's',
    value: 2,
  },
  {
    color: 'blue',
    label: 'm',
    value: 3,
  },
  {
    color: 'green',
    label: 'l',
    value: 5,
  },
  {
    color: 'green',
    label: 'xl',
    value: 8,
  },
  {
    color: 'yellow',
    label: '?',
    value: null,
  },
  {
    color: 'white',
    label: '☕',
    value: null,
  },
];

const DECK_THUMBS: Card[] = [
  {
    color: 'green',
    label: '👍',
    value: 1,
  },
  {
    color: 'red',
    label: '👎',
    value: 2,
  },
  {
    color: 'yellow',
    label: '✋',
    value: null,
  },
];

export namespace Deck {
  export const getDecks = (): Deck[] => {
    return [Deck.Fibonacci, Deck.ModifiedFibonacci, Deck.ShirtSizes];
  };

  export const getName = (deck: Deck): string => {
    switch (deck) {
      case Deck.Fibonacci:
        return 'fibonacci';
      case Deck.ModifiedFibonacci:
        return 'modified fibonacci';
      case Deck.ShirtSizes:
        return 'shirt sizes';
      case Deck.Thumbs:
        return 'thumbs';
      default:
        return '';
    }
  };

  export const getCards = (deck: Deck): Card[] => {
    switch (deck) {
      case Deck.Fibonacci:
        return DECK_FIBONACCI;
      case Deck.ModifiedFibonacci:
        return DECK_MODIFIED_FIBONACCI;
      case Deck.ShirtSizes:
        return DECK_SHIRT;
      case Deck.Thumbs:
        return DECK_THUMBS;
      default:
        return [];
    }
  };
}
