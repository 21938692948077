import classnames from 'classnames';
import React from 'react';

import style from './modal.module.css';

interface Props {
  visible?: boolean;
  onClose?: () => void;
  direction?: string;
  className?: string;
  title?: string;
  buttons?: React.ReactNode[];
}

export const Modal: React.ComponentType<Props> = ({
  children,
  className,
  direction,
  onClose,
  buttons = [],
  visible = false,
}) => (
  <div
    className={classnames(style.container, {
      [style.hidden]: !visible,
    })}
    onClick={onClose}
  >
    <div
      className={classnames(className, style.box, style[direction])}
      onClick={e => e.stopPropagation()}
    >
      {children}
      {buttons.length > 0 && (
        <div className={style.buttons}>
          {buttons.map((button, index) => (
            <div key={index} className={style.button}>
              {button}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);
