import { dom } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { withScroll } from '~/components';

// eslint-disable-next-line css-modules/no-unused-class
import style from './simple.module.css';

export type ElementTypeFromReactType<
  T extends React.ReactType
> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T] extends React.DetailedHTMLProps<
      React.HTMLAttributes<infer U>,
      infer U
    >
    ? U
    : JSX.IntrinsicElements[T] extends React.SVGProps<infer V>
    ? V
    : never
  : T;

function create<
  U extends keyof JSX.IntrinsicElements = 'div',
  T = ElementTypeFromReactType<U>,
  V = JSX.IntrinsicElements[U]
>(
  tag: U,
  name: string,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<V> & React.RefAttributes<T>
> {
  const Component = React.forwardRef<T, V>(({ children, ...props }, ref) =>
    React.createElement(
      tag,
      {
        ...props,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        className: classnames((props as any).className, style[name]),
        ref,
      },
      children,
    ),
  );

  Component.displayName = name;

  return Component;
}

export const DarkTheme = create('div', 'dark-theme');
export const LightTheme = create('div', 'light-theme');
export const Body = create('div', 'body');
export const Bottom = create('div', 'bottom');
export const Container = create('div', 'container');
export const Content = create('div', 'content');
export const Drawer = create('div', 'drawer');
export const DrawerTitle = create('div', 'drawer-title');
export const Header = create('div', 'header');
export const HeaderButton = create('button', 'header-button');
export const HeaderMenu = create('div', 'header-menu');
export const HeaderTitle = create('div', 'header-title');
export const List = create('ul', 'list');
export const ListHeader = create('li', 'list-header');
export const ListItem = create('li', 'list-item');
export const ListItemAction = create('button', 'list-item-action');
export const ListItemActions = create('div', 'list-item-actions');
export const ListItemColumn = create('div', 'list-item-column');
export const Option = create('button', 'option');
export const OptionGroup = create('div', 'option-group');
export const PlayingCardSlot = create('div', 'playing-card-slot');
export const Section = create('div', 'section');
export const SectionTitle = create('div', 'section-title');
export const SingleInputForm = create('form', 'single-input-form');
export const Top = create('div', 'top');
export const Help = create('div', 'help');

export const SmartHeaderBase: React.ComponentType<JSX.IntrinsicElements['div'] & {
  scroll?: number;
}> = ({
  scroll,
  ...props
}: Omit<JSX.IntrinsicElements['div'], 'ref'> & {
  scroll?: number;
}) => <Header {...props} data-top={scroll > 10 ? 'false' : 'true'} />;

export const SmartHeader = withScroll({ throttle: 200 })(SmartHeaderBase);

export const Icon: React.ComponentType<{
  icon: string;
  color?: string;
} & JSX.IntrinsicElements['i']> = ({ icon, color, ...props }) => {
  const ref = React.createRef<HTMLElement>();

  React.useEffect(() => {
    dom.i2svg({
      callback: null,
      node: ref.current.parentNode,
    });
  }, [icon]);

  return (
    <i ref={ref} className={`fas fa-${icon}`} style={{ color }} {...props} />
  );
};

export const Logo: React.ComponentType = () => (
  <Link className={style.logo} to="/">
    planning poker
  </Link>
);
