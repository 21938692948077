import * as React from 'react';

interface WithScrollOptions {
  throttle?: number;
}

export const withScroll = ({ throttle }: WithScrollOptions = {}) => <
  P extends { scroll?: number }
>(
  Component: React.ComponentType<P>,
) => (props: P) => {
  const [scroll, setScroll] = React.useState(0);
  const [timeout, setTimeout] = React.useState(null);
  const updateScroll = (): void => {
    setTimeout(null);
    setScroll(window.scrollY);
  };

  const handleScroll = (): void => {
    if (throttle) {
      if (!timeout) {
        updateScroll();
        window.setTimeout(updateScroll, throttle);
      }
    } else {
      updateScroll();
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <Component {...props} scroll={scroll} />;
};
