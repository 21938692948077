import classnames from 'classnames';
import React from 'react';

import { Icon } from '~/components';
import { Card } from '~/types';

// eslint-disable-next-line css-modules/no-unused-class
import style from './playing-card.module.css';

interface Props {
  card: Card;
  onClick?: React.MouseEventHandler;
  hidden?: boolean;
  fade?: boolean;
}

export const PlayingCard: React.ComponentType<Props> = ({
  card,
  onClick,
  hidden,
  fade,
}) => (
  <div className={style.container}>
    <div
      className={classnames(style.card, {
        [style.empty]: !card,
        [style.flipped]: hidden,
        [style.clickable]: !!onClick,
        [style.fade]: fade,
      })}
      onClick={onClick}
    >
      {card && (
        <>
          <div
            data-disabled={onClick ? 'false' : 'true'}
            className={classnames(style.front)}
          >
            {hidden || (
              <>
                <div
                  className={classnames(style.accent, {
                    [style[card.color]]: !hidden,
                  })}
                />
                <div className={style.label}>{card.label}</div>
              </>
            )}
          </div>
          <div
            data-disabled={onClick ? 'false' : 'true'}
            className={style.back}
          >
            <Icon icon="comment-dots" />
          </div>
        </>
      )}
    </div>
  </div>
);
