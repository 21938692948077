import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import {
  Badge,
  Icon,
  List,
  ListItem,
  ListItemAction,
  ListItemActions,
} from '~/components';
import { MapDispatchToProps, MapStateToProps } from '~/redux';
import { setTargetPlayerRole } from '~/redux/game';
import { Player, Role } from '~/types';

import style from './player-list.module.css';

interface OwnProps {
  players: Player.Collection;
}

interface StateProps {
  activePlayer: Player;
}

interface DispatchProps {
  setTargetPlayerRole: (id: Player.Id, role: Role) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps;

export const BasePlayerList: React.ComponentType<Props> = ({
  players,
  activePlayer,
  setTargetPlayerRole,
}) => (
  <List>
    {Player.Collection.entries(players)
      .sort(([_a, a], [_b, b]) => b.role - a.role)
      .map(([id, player]) => ({
        canChangeRole:
          activePlayer.isUser &&
          Player.hasRole(activePlayer, Role.Host) &&
          player.isUser &&
          player.id !== activePlayer.id &&
          !Player.hasRole(player, Role.Creator),
        id,
        player,
      }))
      .map(({ id, player, canChangeRole }) => (
        <ListItem
          key={id}
          className={classnames({
            [style.inactive]: !player.isPresent,
          })}
        >
          <Badge className={style.badge} player={player} />
          <div className={style.name}>{player.name}</div>
          {canChangeRole && (
            <ListItemActions>
              {(Player.hasRole(player, Role.Host) && (
                <ListItemAction
                  onClick={() => setTargetPlayerRole(player.id, Role.Player)}
                >
                  <Icon icon="user-minus" title="remove host" />
                </ListItemAction>
              )) || (
                <ListItemAction
                  onClick={() => setTargetPlayerRole(player.id, Role.Host)}
                >
                  <Icon icon="user-plus" title="make host" />
                </ListItemAction>
              )}
            </ListItemActions>
          )}
        </ListItem>
      ))}
  </List>
);

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  activePlayer: state.players[state.activePlayerId],
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  setTargetPlayerRole: (id, role) => dispatch(setTargetPlayerRole(id, role)),
});

export const PlayerList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasePlayerList);
