import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Icon,
  List,
  ListItem,
  ListItemAction,
  ListItemActions,
  ListItemColumn,
} from '~/components';
import { BadgeList } from '~/components/badge-list';
import { Button } from '~/components/button';
import { MapDispatchToProps, MapStateToProps } from '~/redux';
import { deleteRoom, leaveRoom } from '~/redux/rooms';
import { Player, Role, Room, User } from '~/types';

import { Modal } from './modal';
import style from './room-list.module.css';

interface OwnProps {
  rooms: Room[];
  title: string;
}

interface StateProps {
  users: User.Collection;
}

interface DispatchProps {
  deleteRoom: (id: Room.Id) => Promise<boolean>;
  leaveRoom: (id: Room.Id) => Promise<boolean>;
}

type Props = OwnProps & StateProps & DispatchProps;

export const BaseRoomList: React.ComponentType<Props> = ({
  deleteRoom,
  leaveRoom,
  rooms,
  title,
  users,
}) => {
  if (rooms.length === 0) return null;

  const [roomToDelete, setRoomToDelete] = React.useState<Room>(null);
  const [roomToLeave, setRoomToLeave] = React.useState<Room>(null);

  return (
    <div>
      <h1>{title}</h1>
      <List>
        {rooms.map(room => (
          <Link key={room.id} className={style.link} to={`/games/${room.id}`}>
            <ListItem key={room.id}>
              <ListItemColumn>{room.name}</ListItemColumn>
              <ListItemColumn>
                <BadgeList
                  players={room.userIds.map(userId =>
                    Player.createFromUser(users[userId]),
                  )}
                />
              </ListItemColumn>
              <ListItemActions>
                {Role.has(room.userRole, Role.Creator) && (
                  <ListItemAction
                    onClick={event => {
                      event.preventDefault();
                      setRoomToDelete(room);
                    }}
                    title="delete"
                  >
                    <Icon icon="trash" />
                  </ListItemAction>
                )}
                {Role.has(room.userRole, Role.Creator) || (
                  <ListItemAction
                    onClick={event => {
                      event.preventDefault();
                      setRoomToLeave(room);
                    }}
                    title="leave"
                  >
                    <Icon icon="sign-out-alt" />
                  </ListItemAction>
                )}
              </ListItemActions>
            </ListItem>
          </Link>
        ))}
      </List>
      <Modal
        visible={!!roomToDelete}
        buttons={[
          <Button
            key="delete"
            data-selected="true"
            onClick={() => {
              deleteRoom(roomToDelete.id);
              setRoomToDelete(null);
            }}
          >
            Delete
          </Button>,
          <Button key="cancel" onClick={() => setRoomToDelete(null)}>
            Cancel
          </Button>,
        ]}
      >
        are you sure you want to delete room: <br />
        <strong>{roomToDelete && roomToDelete.name}</strong>
      </Modal>
      <Modal
        visible={!!roomToLeave}
        buttons={[
          <Button
            data-selected="true"
            key="delete"
            onClick={() => {
              leaveRoom(roomToLeave.id);
              setRoomToLeave(null);
            }}
          >
            Leave
          </Button>,
          <Button key="cancel" onClick={() => setRoomToLeave(null)}>
            Cancel
          </Button>,
        ]}
      >
        are you sure you want to leave room: <br />
        <strong>{roomToLeave && roomToLeave.name}</strong>
      </Modal>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  users: state.users,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  deleteRoom: roomId => dispatch(deleteRoom(roomId)),
  leaveRoom: roomId => dispatch(leaveRoom(roomId)),
});

export const RoomList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseRoomList);
