import { Reducer, Thunk } from '~/redux';
import { Game, Room, User } from '~/types';

import { receiveRooms } from './rooms';

export type State = User.Collection;

export const RECEIVE_USERS = 'RECEIVE_USERS';
export const JOIN_GAME = 'JOIN_GAME';
export const LEAVE_GAME = 'LEAVE_GAME';

interface ReceiveUsersAction {
  type: typeof RECEIVE_USERS;
  users: Record<User.Id, Partial<User>>;
}

export const receiveUsers = (users: User.Collection): ReceiveUsersAction => ({
  type: RECEIVE_USERS,
  users,
});

interface JoinGameAction {
  type: typeof JOIN_GAME;
  userId: User.Id;
  gameId: Game.Id;
}

interface LeaveGameAction {
  type: typeof LEAVE_GAME;
  userId: User.Id;
  gameId: Game.Id;
}

export type Action = ReceiveUsersAction | JoinGameAction | LeaveGameAction;

export const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_USERS:
      return User.Collection.update(state, action.users);
    default:
      return state;
  }
};

export const getRooms = (userId: User.Id): Thunk => async (
  dispatch,
  getState,
) => {
  const { resources } = getState();
  const rooms = await resources.users.getRooms(userId);
  dispatch(receiveRooms(rooms));
};

export const getUsers = (roomId: Room.Id): Thunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const users = await state.resources.rooms.getUsers(roomId);

  dispatch(receiveUsers(users));
};
