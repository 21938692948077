import * as React from 'react';
import { connect } from 'react-redux';

import { FetchDataComponent, MapStateToProps } from '~/redux';
import { User } from '~/types';

import { Dashboard } from './dashboard';
import { Splash } from './splash';

interface StateProps {
  activeUser: User;
}

type Props = StateProps;

export const BaseLanding: FetchDataComponent<Props> = ({ activeUser }) => {
  if (activeUser) {
    return <Dashboard />;
  } else {
    return <Splash />;
  }
};

BaseLanding.fetchData = async (dispatch, getState) => {
  const { activeUserId } = getState();

  if (activeUserId) {
    await Dashboard.fetchData(dispatch, getState);
  }
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  activeUser: state.users[state.activeUserId] || null,
});

export const Landing = connect(mapStateToProps)(BaseLanding);
