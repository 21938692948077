import { Action, AnyAction, Middleware } from 'redux';

type PromiseDispatch = <T extends AnyAction>(promise: Promise<T>) => Promise<T>;

export const middleware = (): Middleware<PromiseDispatch> => ({
  dispatch,
}) => next => <T extends Action>(action: AnyAction | Promise<T>) => {
  if (action instanceof Promise) {
    return action.then(dispatch);
  }

  return next(action);
};
