import React from 'react';

import { Player } from '~/types';

import style from './badge.module.css';

export const initials = (name: string): string => {
  const words = name.split(' ').filter(word => !!word);

  if (words.length === 1) {
    const word = words[0];
    return word.slice(0, 1).toUpperCase() + word.slice(1, 2).toLowerCase();
  }

  return words
    .map(word => word.slice(0, 1))
    .join('')
    .substr(0, 2)
    .toUpperCase();
};

interface Props {
  player: Player;
  className?: string;
  overlay?: string;
}

export const Badge: React.ComponentType<Props> = ({
  className,
  player,
  overlay,
}) => {
  return (
    <div className={className}>
      <div className={style.badge}>
        {player.avatar ? (
          <img
            className={style.image}
            src={`${player.avatar}=s48`}
            title={player.name}
          />
        ) : (
          <div className={style.initials} title={player.name}>
            {initials(player.name)}
          </div>
        )}
        {overlay ? <div className={style.overlay}>{overlay}</div> : null}
      </div>
    </div>
  );
};
