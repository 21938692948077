import { v4 as uuid } from 'uuid';

import { Deck, Player, Room, Rounding } from '~/types';

export enum GameError {
  NOT_FOUND,
  REMOVED,
}

export interface Game {
  canChangeCard: boolean;
  deck: Deck;
  id: Game.Id;
  isRoom: boolean;
  mode: Game.Mode;
  name: string;
  playerIds: Player.Id[];
  rounding: Rounding;
  error?: GameError;
}

export namespace Game {
  export type Id = string;
  export type Collection = Record<Game.Id, Game>;

  export enum Mode {
    Hidden,
    Visible,
  }

  export const create = (defaults: Partial<Game> = {}): Game => ({
    canChangeCard: false,
    deck: Deck.ModifiedFibonacci,
    id: uuid(),
    isRoom: false,
    mode: Mode.Hidden,
    name: '',
    playerIds: [],
    rounding: Rounding.Up,
    ...defaults,
  });

  export const createFromRoom = (room: Room): Game =>
    Game.create({
      canChangeCard: room.canChangeCard,
      deck: room.deck,
      id: room.id,
      isRoom: true,
      name: room.name,
      rounding: room.rounding,
    });
}
