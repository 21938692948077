import { Reducer, Thunk } from '~/redux';
import { Game, Player } from '~/types';

import * as activePlayerId from './active-player-id';

export type State = Player.Collection;

export const RECEIVE_PLAYERS = 'RECEIVE_PLAYERS';
export const CLEAR_PLAYERS = 'CLEAR_PLAYERS';

interface ReceivePlayersAction {
  type: typeof RECEIVE_PLAYERS;
  players: Record<Player.Id, Partial<Player>>;
}

export const receivePlayers = (
  players: Player.Collection,
): ReceivePlayersAction => ({
  players,
  type: RECEIVE_PLAYERS,
});

interface ClearPlayersAction {
  type: typeof CLEAR_PLAYERS;
}

export const clearPlayers = (): ClearPlayersAction => ({
  type: CLEAR_PLAYERS,
});

export type Action = ReceivePlayersAction | ClearPlayersAction;

export const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PLAYERS:
      return Player.Collection.update(state, action.players);
    case CLEAR_PLAYERS:
      return {};
    default:
      return state;
  }
};

export const getPlayers = (gameId: Game.Id): Thunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const players = await state.resources.games.getPlayers(gameId);

  dispatch(receivePlayers(players));

  if (players[state.activeUserId]) {
    dispatch({
      id: state.activeUserId,
      type: activePlayerId.SET_ACTIVE_PLAYER_ID,
    });
  }
};
