import * as React from 'react';

import {
  Bottom,
  Content,
  CreateGame,
  GoogleLogin,
  HeaderMenu,
  SmartHeader,
  Top,
} from '~/components';

export const Splash: React.ComponentType = () => (
  <Content>
    <SmartHeader>
      <HeaderMenu>
        <GoogleLogin />
      </HeaderMenu>
    </SmartHeader>
    <Top data-large="true">
      <h1>planning poker</h1>
    </Top>
    <Bottom>
      <CreateGame />
    </Bottom>
  </Content>
);
