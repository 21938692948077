import { RoomsApi } from '~/types';

import { create, destroy, get, put } from './rest';

export const methods: RoomsApi = {
  createRoom: partial => create(`/api/rooms`, partial),
  deleteRole: (roomId, userId) =>
    destroy(`/api/rooms/${roomId}/roles/${userId}`),
  deleteRoom: id => destroy(`/api/rooms/${id}`),
  getRole: (roomId, userId) => get(`/api/rooms/${roomId}/users/${userId}`),
  getRoom: id => get(`/api/rooms/${id}`),
  getRooms: () => get(`/api/rooms`),
  getUsers: id => get(`/api/rooms/${id}/users`),
  setRole: (roomId, userId, role) =>
    create(`/api/rooms/${roomId}/roles/${userId}`, { role }),
  updateRoom: (id, partial) => put(`/api/rooms/${id}`, partial),
};
