import { Reducer } from '~/redux';
import { Player } from '~/types';

export type State = Player.Id;

const initialState: State = null;

export const SET_ACTIVE_PLAYER_ID = 'SET_ACTIVE_PLAYER_ID';

interface SetActivePlayerIdAction {
  type: typeof SET_ACTIVE_PLAYER_ID;
  id: Player.Id;
}

export type Action = SetActivePlayerIdAction;

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_ACTIVE_PLAYER_ID:
      return action.id;
    default:
      return state;
  }
};
