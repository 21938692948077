import copy from 'copy-to-clipboard';
import React from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Drawer,
  DrawerTitle,
  GoogleLogin,
  HeaderButton,
  HeaderMenu,
  Icon,
  Option,
  OptionGroup,
  PlayerList,
  QrCode,
  Section,
  SectionTitle,
  SingleInputForm,
} from '~/components';
import { Help } from '~/components/simple';
import { MapDispatchToProps, MapStateToProps } from '~/redux';
import {
  saveGame,
  setGameCanChangeCard,
  setGameDeck,
  setGameName,
  setGameRounding,
  setPlayerIsPlaying,
  setPlayerName,
} from '~/redux/game';
import { Deck, Game, Player, Role, Rounding } from '~/types';

interface StateProps {
  game: Game;
  players: Player.Collection;
  activePlayer: Player;
}

interface DispatchProps {
  saveGame(): Promise<void>;
  setGameCanChangeCard(canChangeCard: boolean): Promise<void>;
  setGameDeck(deck: number): Promise<void>;
  setGameName(name: string): Promise<void>;
  setGameRounding(rounding: number): Promise<void>;
  setPlayerIsPlaying(isPlaying: boolean): Promise<void>;
  setPlayerName(name: string): Promise<void>;
}

type Props = StateProps & DispatchProps;

const hasAncestor = (element: HTMLElement, ancestor: HTMLElement): boolean => {
  while (element) {
    if (element === ancestor) return true;
    element = element.parentNode as HTMLElement;
  }
  return false;
};

export const BaseMenu: React.ComponentType<Props> = ({
  activePlayer,
  game,
  players,
  saveGame,
  setGameCanChangeCard,
  setGameDeck,
  setGameName,
  setGameRounding,
  setPlayerIsPlaying,
  setPlayerName,
}) => {
  const menuEl = React.useRef(null);
  const shareEl = React.useRef(null);
  const location = typeof window !== 'undefined' ? `${window.location}` : '';

  const [pendingGameName, setPendingGameName] = React.useState<string>(null);
  const [pendingPlayerName, setPendingPlayerName] = React.useState<string>(
    null,
  );

  const [pane, setPane] = React.useState('');
  const clearPane: EventListener = event => {
    if (hasAncestor(event.target as HTMLElement, menuEl.current)) return;
    setPane('');
  };
  const togglePane = (to: string): void => {
    pane === to ? setPane('') : setPane(to);
  };

  React.useEffect(() => {
    document.addEventListener('click', clearPane);
    return () => document.removeEventListener('click', clearPane);
  }, []);

  const onSubmitSaveGame = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    saveGame();
  };

  const onSubmitGameName = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (pendingGameName) {
      setGameName(pendingGameName);
      setPendingGameName(null);
    }
  };

  const onSubmitPlayerName = (
    event: React.FormEvent<HTMLFormElement>,
  ): void => {
    event.preventDefault();

    if (pendingPlayerName) {
      setPlayerName(pendingPlayerName);
      setPendingPlayerName(null);
    }
  };

  const onCopyLink = (event: React.FormEvent<unknown>): void => {
    event.preventDefault();
    shareEl.current.select();
    copy(location);
  };

  const hosts = Object.values(players).filter(player =>
    Player.hasRole(player, Role.Host),
  );

  const users = Object.values(players).filter(
    player => !Player.hasRole(player, Role.Host) && player.isUser,
  );

  const guests = Object.values(players).filter(
    player => player.role === Role.Player && !player.isUser && player.isPresent,
  );

  /*
  <p>
    You are encouraged to start any new games there already and rooms
    saved before <u>September 1st, 2024</u> have been migrated with the
    same IDs and users.
  </p>
*/

  return (
    <HeaderMenu ref={menuEl}>
      <HeaderButton
        disabled={false}
        onClick={() => togglePane('estimations')}
        data-active={pane === 'estimations'}
        data-notice={true}
      >
        <Icon icon="bullhorn" />
      </HeaderButton>

      <HeaderButton
        disabled={false}
        onClick={() => togglePane('share')}
        data-active={pane === 'share'}
      >
        <Icon icon="share-alt" />
      </HeaderButton>

      {Player.hasRole(activePlayer, Role.Host) && (
        <HeaderButton
          onClick={() => togglePane('game')}
          data-active={pane === 'game'}
        >
          <Icon icon="cog" />
        </HeaderButton>
      )}

      {Player.hasRole(activePlayer, Role.Host) && (
        <HeaderButton
          onClick={() => togglePane('players')}
          data-active={pane === 'players'}
        >
          <Icon icon="users" />
        </HeaderButton>
      )}

      <HeaderButton
        onClick={() => togglePane('user')}
        data-active={pane === 'user'}
      >
        <Icon icon="user" />
      </HeaderButton>

      <Drawer data-open={pane === 'estimations'}>
        <Section>
          <p>
            I made <strong>planningpoker.io</strong> in 2017 for only my small
            team as a simple alternative to the for-pay tools we kept finding
            online.
          </p>

          <p>
            Since then, by word of mouth alone, it now has thousands of daily
            users.
          </p>

          <p>
            Only a couple years after releasing this app I made{' '}
            <strong>estimations.io</strong> and have been running it at the same
            time. It has all the same things, is a little more refined, and has
            a few extra features while I keep working on it.
          </p>

          <p>
            Starting in 2025 I will be hosting only{' '}
            <strong>estimations.io</strong>. This site will remain behind for a
            while after to redirect there.
          </p>

          <p>
            Thanks for using this little tool I made and I hope you will
            continue to find it helpful over at <strong>estimations.io</strong>.
          </p>

          <SingleInputForm>
            <Button data-cta={true} href={'https://estimations.io/planning-poker'}>
              <img height={24} src="/estimations.png" /> start using
              estimations.io
            </Button>
          </SingleInputForm>
        </Section>
      </Drawer>

      <Drawer data-open={pane === 'share'}>
        <DrawerTitle>share</DrawerTitle>
        <Section>
          <QrCode url={location} />
        </Section>
        <Section>
          <SingleInputForm onSubmit={onCopyLink}>
            <input
              value={location}
              ref={shareEl}
              onClick={onCopyLink}
              readOnly
            />
            <button>copy</button>
          </SingleInputForm>
        </Section>
      </Drawer>

      <Drawer data-open={pane === 'user'}>
        <DrawerTitle>player settings</DrawerTitle>
        <Section>
          <SectionTitle>player name</SectionTitle>
          <SingleInputForm onSubmit={onSubmitPlayerName}>
            <input
              maxLength={64}
              placeholder="your name"
              defaultValue={
                pendingPlayerName === null
                  ? activePlayer.name
                  : pendingPlayerName
              }
              onChange={e => setPendingPlayerName(e.target.value)}
            />
            {pendingPlayerName && pendingPlayerName !== activePlayer.name && (
              <button>save</button>
            )}
          </SingleInputForm>
        </Section>
        <Section>
          <SectionTitle>playing</SectionTitle>
          <OptionGroup>
            {[true, false].map(isPlaying => (
              <Option
                key={isPlaying ? 'yes' : 'no'}
                data-selected={
                  isPlaying === activePlayer.isPlaying ? 'true' : 'false'
                }
                onClick={() => setPlayerIsPlaying(isPlaying)}
              >
                {isPlaying ? 'playing' : 'spectating'}
              </Option>
            ))}
          </OptionGroup>
          <Help>
            <p>choose whether you are participating in the estimation</p>
          </Help>
        </Section>
        {activePlayer.isUser || (
          <Section>
            <SingleInputForm>
              <GoogleLogin redirect={`/games/${game.id}`} />
            </SingleInputForm>
          </Section>
        )}
        {activePlayer.isUser && (
          <Section>
            <SingleInputForm>
              <Button href="/api/auth/logout">log out</Button>
            </SingleInputForm>
          </Section>
        )}
      </Drawer>

      {Player.hasRole(activePlayer, Role.Host) && (
        <Drawer data-open={pane === 'players'}>
          <DrawerTitle>players</DrawerTitle>
          {hosts.length > 0 && (
            <Section>
              <SectionTitle>hosts</SectionTitle>
              <PlayerList players={Player.Collection.fromArray(hosts)} />
            </Section>
          )}
          {users.length > 0 && (
            <Section>
              <SectionTitle>users</SectionTitle>
              <PlayerList players={Player.Collection.fromArray(users)} />
            </Section>
          )}
          {guests.length > 0 && (
            <Section>
              <SectionTitle>guests</SectionTitle>
              <PlayerList players={Player.Collection.fromArray(guests)} />
            </Section>
          )}
        </Drawer>
      )}

      {Player.hasRole(activePlayer, Role.Host) && (
        <Drawer data-open={pane === 'game'}>
          <DrawerTitle>game settings</DrawerTitle>

          <Section>
            <SectionTitle>game name</SectionTitle>
            <SingleInputForm onSubmit={onSubmitGameName}>
              <input
                maxLength={64}
                placeholder="game name"
                defaultValue={
                  pendingGameName === null ? game.name : pendingGameName
                }
                onChange={e => setPendingGameName(e.target.value)}
              />
              {pendingGameName && pendingGameName !== game.name && (
                <button>save</button>
              )}
            </SingleInputForm>
          </Section>

          {!game.isRoom && (
            <Section>
              <SectionTitle>save game</SectionTitle>

              <Help>
                keep a game going forever by saving it. you will be able to use
                the same url indefinitely
              </Help>

              <SingleInputForm onSubmit={onSubmitSaveGame}>
                {!activePlayer.isUser ? (
                  <button disabled>you must be logged in to save</button>
                ) : game.name.length === 0 ? (
                  <button disabled>game must have a name</button>
                ) : (
                  <button>save</button>
                )}
              </SingleInputForm>
              {activePlayer.isUser || (
                <GoogleLogin fullWidth redirect={`/games/${game.id}`} />
              )}
            </Section>
          )}

          <Section>
            <SectionTitle>deck</SectionTitle>
            <OptionGroup>
              {Deck.getDecks().map(d => (
                <Option
                  key={d}
                  data-selected={d === game.deck ? 'true' : 'false'}
                  onClick={() => setGameDeck(d)}
                >
                  <div>{Deck.getName(d)}</div>
                </Option>
              ))}
            </OptionGroup>
            <Help>
              <p>choose the cards that work best for your team:</p>
              <ul>
                <li>
                  <strong>fibonacci</strong> <br />
                  estimate with exponential degrees of difficulty
                </li>
                <li>
                  <strong>modified fibonacci</strong> <br />
                  similar to the fibonacci deck, but with simplified large
                  numbers and a half-point
                </li>
                <li>
                  <strong>shirt sizes</strong> <br />
                  usually for understanding the relative feasability of far-off
                  features
                </li>
              </ul>
            </Help>
          </Section>

          <Section>
            <SectionTitle>rounding</SectionTitle>
            <OptionGroup>
              {Rounding.getRoundings().map(r => (
                <Option
                  key={r}
                  data-selected={r === game.rounding ? 'true' : 'false'}
                  onClick={() => setGameRounding(r)}
                >
                  {Rounding.getName(r)}
                </Option>
              ))}
            </OptionGroup>
            <Help>
              <p>
                round the average estimate up or down to the nearest card in the
                deck
              </p>
            </Help>
          </Section>

          <Section>
            <SectionTitle>change card</SectionTitle>
            <OptionGroup>
              {[true, false].map(canChangeCard => (
                <Option
                  key={canChangeCard ? 'yes' : 'no'}
                  data-selected={
                    canChangeCard === game.canChangeCard ? 'true' : 'false'
                  }
                  onClick={() => setGameCanChangeCard(canChangeCard)}
                >
                  {canChangeCard ? 'yes' : 'no'}
                </Option>
              ))}
            </OptionGroup>
            <Help>
              <p>
                choose whether players can change their estimate after the cards
                have been revealed
              </p>
            </Help>
          </Section>
        </Drawer>
      )}
    </HeaderMenu>
  );
};

const mapStateToProps: MapStateToProps<StateProps> = state => ({
  activePlayer: state.players[state.activePlayerId],
  game: state.game,
  players:
    state.game && state.game.playerIds
      ? Player.Collection.fromArray(
          state.game.playerIds
            .map(id => state.players[id])
            .filter(player => !!player),
        )
      : {},
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = dispatch => ({
  saveGame: () => dispatch(saveGame()),
  setGameCanChangeCard: canChangeCard =>
    dispatch(setGameCanChangeCard(canChangeCard)),
  setGameDeck: deck => dispatch(setGameDeck(deck)),
  setGameName: name => dispatch(setGameName(name)),
  setGameRounding: rounding => dispatch(setGameRounding(rounding)),
  setPlayerIsPlaying: isPlaying => dispatch(setPlayerIsPlaying(isPlaying)),
  setPlayerName: name => dispatch(setPlayerName(name)),
});

export const Menu = connect(mapStateToProps, mapDispatchToProps)(BaseMenu);
