import { Card } from '~/types';

export enum Rounding {
  Up,
  Down,
}

export namespace Rounding {
  export const getRoundings = (): Rounding[] => {
    return [Rounding.Up, Rounding.Down];
  };

  export const getName = (rounding: Rounding): string => {
    switch (rounding) {
      case Rounding.Up:
        return 'up';
      case Rounding.Down:
        return 'down';
    }
  };

  export const calculate = (
    rounding: Rounding,
    cards: Card[],
    choices: number[], // card index in deck
  ): Card => {
    const values = choices
      .map(choice => cards[choice])
      .filter(card => card !== undefined)
      .filter(card => card.value !== null)
      .map(card => card.value);
    switch (rounding) {
      case Rounding.Up: {
        const average = values.reduce((acc, n) => acc + n, 0) / values.length;
        const result = cards
          .filter(card => card.value !== null)
          .find(card => card.value >= average);
        return result === undefined ? null : result;
      }
      case Rounding.Down: {
        const average = values.reduce((acc, n) => acc + n, 0) / values.length;
        const result = cards
          .filter(card => card.value !== null)
          .reverse()
          .find(card => card.value <= average);
        return result === undefined ? null : result;
      }
    }
  };
}
