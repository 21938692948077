type Key = string | number;
type Collection<T> = Record<Key, T>;
type CollectionItem = { id: Key };

const fromEntries = <T>(array: Array<[Key, T]>): Collection<T> => {
  return array.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Collection<T>);
};

export const arrayToCollection = <Item extends CollectionItem>(
  array: Item[],
): Collection<Item> => fromEntries(array.map(item => [item.id, item]));

export const keys = <Item extends CollectionItem>(
  collection: Collection<Item>,
): Array<Key> => {
  return Object.keys(collection) as Array<Key>;
};

export const entries = <Item extends CollectionItem>(
  collection: Collection<Item>,
): Array<[Key, Item]> => {
  return keys(collection).map(id => [id, collection[id]]);
};

export const map = <Item extends CollectionItem, T>(
  collection: Collection<Item>,
  fn: (item: [Key, Item], index: number, list: Array<[Key, Item]>) => [Key, T],
): Collection<T> => {
  return fromEntries(entries(collection).map(fn));
};

const partition = <T>(list: T[], predicate: (item: T) => boolean): [T[], T[]] =>
  list.reduce(
    ([left, right], item) => {
      const side = predicate(item) ? left : right;
      side.push(item);
      return [left, right];
    },
    [[], []] as [T[], T[]],
  );

export const merge = <
  Item,
  Collection extends Record<Key, Item>,
  PartialCollection extends Record<Key, Partial<Item>>
>(
  left: Collection,
  right: PartialCollection,
): Collection =>
  Object.keys(right).reduce(
    (left, id) => ({
      ...left,
      [id]: {
        ...left[id],
        ...right[id],
      },
    }),
    { ...left },
  );

export const without = <Item, Collection extends Record<Key, Item>>(
  list: Collection,
  ids: Key[],
): Collection =>
  ids.reduce((list, id) => {
    const { [id]: _, ...rest } = list;

    return rest as Collection;
  }, list);

export const update = <
  Item extends CollectionItem,
  PartialCollection extends Record<Key, Partial<Item>>
>(
  left: Collection<Item>,
  right: PartialCollection,
): Collection<Item> => {
  const [updateIds, deleteIds] = partition(
    Object.keys(right),
    key => right[key] !== null,
  );

  const updates = arrayToCollection(
    updateIds.map(id => ({
      ...right[id],
      id,
    })),
  );

  return without(merge(left, updates), deleteIds);
};
