import { toDataURL } from 'qrcode';
import * as React from 'react';

interface Props {
  url: string;
}

export const BaseQrCode: React.ComponentType<Props> = ({ url }) => {
  const [data, setData] = React.useState('');

  React.useEffect(() => {
    toDataURL(url, {
      color: {
        dark: '#333333FF',
      },
      margin: 1,
      scale: 8,
    }).then(setData);
  }, [url]);

  return <img src={data} style={{ width: '100%' }} />;
};

export const QrCode = React.memo(BaseQrCode);
