import * as React from 'react';

import { Button } from '~/components';

import style from './google-login.module.css';

interface Props {
  redirect?: string;
  fullWidth?: boolean;
}

export const GoogleLogin: React.ComponentType<Props> = ({
  redirect,
  ...props
}) => (
  <Button
    {...props}
    href={
      redirect ? `/api/auth/google?redirect=${redirect}` : `/api/auth/google`
    }
  >
    <span className={style.content}>
      <span className={style.svg} />
      <span className={style.text}>sign in with google</span>
    </span>
  </Button>
);
