export const extract = async <T>(response: Response): Promise<T> => {
  if (response.status === 200) {
    const payload: { data: T } = await response.json();
    return payload.data;
  } else {
    return null;
  }
};

export const success = (response: Response): boolean => {
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const get = async <T>(url: string): Promise<T> => {
  return await extract(await fetch(url));
};

export const create = async <T>(url: string, data?: object): Promise<T> => {
  const response = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return await extract(response);
};

export const destroy = async (url: string): Promise<boolean> => {
  const response = await fetch(url, {
    method: 'DELETE',
  });

  return success(response);
};

export const put = async <T>(url: string, data: object): Promise<T> => {
  const response = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });

  return await extract(response);
};
